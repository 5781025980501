:root {
  --left-value: translateX(-100%);
  --right-value: translateX(100%);
}

.carousel-image img {
  width: 100%;
  max-width: 9em;
  height: 9em;
}

.brand-arrow-left,
.brand-arrow-right {
  position: absolute;
  top: 45%;
  cursor: pointer;
}

.brand-arrow-left {
  left: -30px;
}

.brand-arrow-right {
  right: -30px;
}

.reverse-brand {
  direction: rtl;
  transform: var(--right-value);
  transition: all 1000ms ease-in-out;
}

.expand-brand,
.expand-right-brand {
  transform: var(--right-value);
  transition: all 1000ms ease-in-out;
}

.expand-left-brand {
  transform: var(--left-value);
  transition: all 1000ms ease-in-out;
}
/*
@media only screen and (max-width: 1200px) {
  .carousel-image img {
    height: 7em;
  }
}

@media only screen and (max-width: 992px) {
  .carousel-image img {
    width: 100%;
    height: 6em;
  }
}

@media only screen and (max-width: 600px) {
}
*/
