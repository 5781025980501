.location-wrap {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
  grid-auto-rows: min-content;
  color: black;
  grid-gap: 4px;
  justify-items: start;
  justify-content: start;
  grid-column: 1/-1;
  font-family: "Raleway", sans-serif;
  height: min-content;
  word-wrap: break-word;
}

.location-wrap a {
  color: black;
}
