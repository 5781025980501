.mobile-nav-menu-item-line {
  width: 75%;
  height: 2px;
  background-color: #ecf0f1;
  display: block;
  margin: 3px auto;
}

.mobile-nav-menu-item-line:first-child {
  margin-top: 7px;
}

.ri-shopping-cart-2-fill,
.ri-user-fill {
  color: var(--orange-color);
  margin-right: 3px;
}
