:root {
  --latest-left-value: translateX(-33%);
  --latest-right-value: translateX(33%);
}

.latest-header {
  justify-self: start;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  padding: 1em;
}

.latest-outer {
  display: grid;
}

.latest-heading-s {
  border-top: 3px solid var(--orange-color);
  align-self: end;
  color: var(--orange-color);
  font-weight: 800;
  padding-top: 2px;
  padding-bottom: 3px;
  font-size: 0.8em;
}

.latest-heading-l {
  font-size: 2em;
  color: var(--grey-color);
  font-weight: 600;
}

.latest-image {
  margin: 5px;
  border: solid 6px #9e9e9e;
  display: grid;
  align-items: center;
  background-color: #ffffff;
  transition: all 400ms ease-in-out;
  overflow: hidden;
}

.latest-image:hover {
  border: solid 6px var(--orange-color);
}

.latest-image img {
  width: 100%;
  max-width: 14em;
  height: 14em;
  transition: all 400ms ease-in-out;
}
.latest-image img:hover {
  transform: scale(1.05);
}

.latest-arrow-left,
.latest-arrow-right {
  color: #9e9e9e;
  font-size: 1.5em;
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.latest-arrow-right {
  right: -30px;
}

.latest-arrow-left {
  left: -30px;
}

.latest-info-buy {
  display: grid;
  padding: 1em;
  grid-gap: 10px;
  font-weight: bold;
}

.latest-cart-button {
  background-color: var(--orange-color);
  color: white;
  justify-self: center;
  padding: 5px;
  font-weight: bold;
}

.reverse-latest {
  direction: rtl;
  transform: var(--latest-right-value);
  transition: all 1000ms ease-in-out;
}

.expand-latest,
.expand-right-latest {
  transform: var(--latest-right-value);
  transition: all 1000ms ease-in-out;
}

.expand-left-latest {
  transform: var(--latest-left-value);
  transition: all 1000ms ease-in-out;
}

/*
@media only screen and (max-width: 1200px) {
  .latest-image img {
    width: auto;
    height: 12em;
    justify-self: center;
  }
}

@media only screen and (max-width: 992px) {
  .latest-image img {
    width: auto;
    height: 6em;
    transition: all 400ms ease-in-out;
  }
}

@media only screen and (max-width: 600px) {
}
*/
