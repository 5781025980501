.App {
  text-align: center;
  display: grid;
  grid-template-rows: 9em 1fr auto;
  min-height: 100vh;
  grid-template-columns: 1fr;
  font-family: "Roboto", sans-serif;
  color: #4b4949;
}

@media only screen and (max-width: 920px) {
  .App {
    grid-template-rows: 6em 1fr auto;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.text-link {
  text-decoration: none;
}

.white-text {
  color: white;
}

:root {
  --orange-color: #ff9f00;
  --orange-color-hover: #ef9400;
  --grey-color: rgb(75, 75, 75);
  --grey-button: #898989;
  --grey-button-hover: #6e6e6e;
  --header-color: #5a5454;
  --white-color: white;
  --border-main: solid 1px rgba(0, 0, 0, 0.16);
  --box-shadow-main: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --grey-color-light: #e6e6e6;
  --light-border: #dedbdb;
  --shadow-standard: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.content-wrapper {
  grid-row: 2;
  display: grid;
}

h1,
h2,
h3 {
  color: var(--header-color);
}

.app-location-wrap {
  margin-top: 30px;
  margin-bottom: 30px;
}

.spaced-text {
  line-height: 1.4;
}

b {
  font-weight: 600;
}
