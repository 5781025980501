.error-page {
  height: 100%;
  grid-row: 1/-1;
  display: grid;
  align-items: center;
  align-content: center;
  grid-gap: 20px;
}

.error-page img {
  width: 16em;
  height: auto;
  justify-self: center;
  align-self: end;
}
.error-page h2 {
  font-size: 4em;
}

.return-link {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 4px;
  justify-self: center;
}

.return-link a {
  text-decoration: none;
  color: var(--orange-color);
}
