:root {
  --border-active-color: rgb(162, 162, 162);
}

label {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 3px;
}

.error-outline {
  border-color: #990000;
}

.error-outline:focus {
  border-color: #990000;
}
