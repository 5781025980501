.carousel-wrapper {
  justify-self: center;
  display: grid;
  position: relative;
}

.carousel-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  width: max-content;
  height: max-content;
  overflow: hidden;
}

.right-images {
  position: absolute;
  left: 100%;

  top: 0;
}

.left-images {
  position: absolute;
  right: 100%;
  top: 0;
}

.segment-cover-l {
  background-color: white;
  width: 75%;
  position: absolute;
  top: 0;
  bottom: 0;

  right: 0;
}

.segment-cover-r {
  background-color: white;
  width: 75%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.image-container,
.current-images {
  display: grid;

  grid-auto-flow: column;
  height: 100%;
  width: 100%;
  justify-items: center;
  justify-content: center;
  justify-self: center;
}
